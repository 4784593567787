import React from "react"
import ReactWOW from "react-wow"
import {useStaticQuery, graphql } from "gatsby"
import MiniContactForm from "../Index/MiniContactForm"
import Badges from "../App/Badge"

const Banner = () => {

  const responseData = useStaticQuery(graphql`
      query GetOORatesQuery {
        allStrapiRateCards(filter: {Rate_Name: {in: ["Interest_Rate_OO", "LVR_OO", "Comparison_Rate_OO"]}}) {
          edges {
            node {
              Rate_Name
              Rate_Value
            }
          }
        }
      }
    `);

  const interestRateOO = responseData.allStrapiRateCards.edges.find(edge => edge.node.Rate_Name === "Interest_Rate_OO").node.Rate_Value || "N/A";
  const comparisonRateOO = responseData.allStrapiRateCards.edges.find(edge => edge.node.Rate_Name === "Comparison_Rate_OO").node.Rate_Value || "N/A";
  const lvrOO = responseData.allStrapiRateCards.edges.find(edge => edge.node.Rate_Name === "LVR_OO").node.Rate_Value || "N/A";
  
  return (
    <div className="home-hero-banner">
      <div className="container hero">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12">
            <div className="main-banner-content">
              <div>
                <Badges />
              </div>
              <div>
                <h1>
                  Home Loans Made Simple <sup class="trademark">TM</sup>
                </h1>
                <h2 className="">Find a better home loan in 5 minutes.</h2>

                <ReactWOW delay=".05s" animation="fadeInUp">
                  {/* <h2 className="low-rate-title mt-4 mb-2">Low Rate SMSF Loans​</h2> */}
                  <div className="d-flex justify-content-center justify-content-lg-start">
                    <div className="rates-comparison mb-5">
                      <div className="rates-comparison-wapper">
                        <div className="rates-comparison-group">
                          <label>Rates from</label>
                          <div className="rate-wrapper">
                            <h2 className="rate">{interestRateOO}</h2>
                            <div className="percentage">
                              <p className="percentage-symbol">
                                % <br /> <span>p.a</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="rates-comparison-group ml-2 ml-md-5 ml-lg-5">
                          <label>Comparison</label>
                          <div className="rate-wrapper">
                            <h2 className="rate">{comparisonRateOO}</h2>
                            <div className="percentage">
                              <p className="percentage-symbol">
                                % <br /> <span>p.a</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="owner-occupied">
                        <p>Owner occupied | P&I | {lvrOO}% LVR </p>
                      </div>
                    </div>
                  </div>
                  {/* <p className="low-rate-description mt-3">​​​Oxygen has some of the lowest rate SMSF loans in the market, whether you want to refinance an existing loan or purchase a new property. Check out our range of low rate SMSF loans and start saving thousands today!</p> */}
                </ReactWOW>

                <ReactWOW delay=".05s" animation="fadeInUp">
                  <div className="btn-box">
                    {`${process.env.GATSBY_ENVIRONMENT}` === "staging" ? (
                      <a
                        href={`${process.env.GATSBY_OLAP_BASE_URL}/?SourceType=OxygenWebSite`}
                        className="default-btn primary-btn btn-lg mb-2 mr-sm-3 mr-lg-2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        10 min Home Loan Pre-Approval<span></span>
                      </a>
                    ) : (
                      <a
                        href={`${process.env.GATSBY_OLAP_BASE_URL}/?SourceType=OxygenWebSite`}
                        className="default-btn primary-btn btn-lg mb-2 mr-sm-3 mr-lg-2"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        10 min Home Loan Pre-Approval<span></span>
                      </a>
                    )}
                  </div>
                </ReactWOW>
              </div>
            </div>
          </div>

          <div className="col-lg-5 col-md-12">
            {/* <ReactWOW delay=".1s" animation="fadeInUp">
              <div className="main-banner-image mb-4">
                <img loading="eager" src={bannerImg} alt="banner" />
                {/* <StaticImage
                  src="../../assets/images/hero.svg"
                  placeholder="tracedSVG"
                  layout="fullWidth"
                  quality="100"
                /> }
              </div>
            </ReactWOW> */}
            <MiniContactForm />
          </div>
        </div>
      </div>
      <div className="container highlights">
        <div className="row d-flex justify-stats">
          <div className="col-6 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box">
              <h3>$10+ Billion</h3>
              <p>in home loans settled</p>
            </div>
          </div>

          <div className="col-6 col-sm-4  col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box">
              <h3>27,000+</h3>
              <p>customers served</p>
            </div>
          </div>

          <div className="col-6 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box">
              <h3>19 Years</h3>
              <p>of service and counting</p>
            </div>
          </div>

          <div className="col-6 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box">
              <h3>8x Winner</h3>
              <p>MFAA &amp; AFG Brokerage of the Year</p>
            </div>
          </div>

          <div className="col-6 col-sm-4 col-lg mt-3 mt-lg-0">
            <div className="single-services-item-box">
              <h3>53+</h3>
              <p>Locations + Apply Online</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner