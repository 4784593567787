import React from "react"
import { Link } from "gatsby"
import badgeImg from "../../assets/images/abm-badge.png"
import badgeABA from "../../assets/images/about/img-finalist-aba-award-20222.png"
import innov from "../../assets/images/innvotive.png"
import badgefinal from "../../assets/images/about/AwardFinal.png"

const Badge = () => {
  return (
    <div className="wr-badge mb-5 mb-md-4 mb-xl-4">
      <div className="container">
        <img className="img-inno" src={innov} alt="most innovative companies" />
        <div className="wr-badge-in">
          <img id="img-gold" src={badgeImg} alt="ABW 5-Star Mortgage Innovator 2022 badge" />
          <img className="ml-2" src={badgefinal} alt="Award Final" />
          <img src={badgeABA} alt="Finalist ABA Award 2022 Badge" />
        </div>
      </div>
    </div>
  )
}

export default Badge
